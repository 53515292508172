/** @format */

.tradeRight-table-content {
  width: 400px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgba(190, 200, 221, 0.3);
  border-radius: 8px;
  padding: 22px 0px 0px;
  margin-left: 20px;
  .table-header-tab {
    padding: 0px 35px 0 20px;
    display: flex;
    // border-bottom: 1px solid rgba(#333333, 0.1);
    border-radius: 1px;
    // margin-bottom: 26px;
    margin-bottom: 0;
    position: relative;
    .header-tab-item {
      font-size: 16px;
      font-weight: 500;
      color: #909db4;
      margin-right: 38px;
      padding-bottom: 14px;
      cursor: pointer;
      position: relative;
    }
    svg {
      margin-right: 16px;
    }
    .header-tab-item-active {
      font-size: 16px;
      font-weight: 500;
      color: #005aff;
      &::after {
        content: '';
        width: 29px;
        height: 2px;
        background: #005aff;
        border-radius: 1px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    .icon {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
    }
  }
  .manageRight-addCollateral-container {
    width: 100%;
    padding: 0px 0px;
    .amount + .amount {
      margin-top: 20px;
    }
  }
}
@media (max-width: 750px) {
  .tradeRight-table-content {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    .amount {
      padding: 10px;
    }
    .confirm-order {
      width: 100%;
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;