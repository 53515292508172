/** @format */

.tikerInfo-container {
  .top {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      .logo {
        width: 36px;
        height: 36px;
      }
      .tit {
        margin-left: 10px;
        margin-top: 2px;
        text-align: left;
        .titp {
          font-size: 14px;
          font-weight: 600;
          color: @heading-color;
          line-height: 14px;
          height: 18px;
        }
        span {
          font-size: 12px;
          color: #666666;
          line-height: 12px;
        }
      }
    }
    h5 {
      font-size: 18px;
      font-weight: 600;
      color: @heading-color;
      line-height: 36px;
      margin-bottom: 0;
    }
  }

  .card {
    font-size: 14px;
    font-weight: 600;
    color: @text-color;
    margin-top: 10px;
    line-height: 14px;
    height: 55px;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    .item {
      display: flex;
      justify-content: space-between;
      .itemp {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 14px;
        height: 30px;
      }
      span {
        font-size: 14px;
        color: @heading-color;
        font-weight: 600;
        line-height: 14px;
      }
    }
  }
  .describe {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 20px;
  }
}
@media (max-width: 750px) {
  .logo-img {
    margin-top: 0px;
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;