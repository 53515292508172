/** @format */

.long-container {
  width: 400px;
  padding: 20px 16px;
  background: @component-background;
  border-radius: 8px;
  // margin-left: 20px;
  .short-desc {
    font-size: 12px;
    color: #909db4;
    margin-bottom: 12px;
    line-height: 20px;
    display: inline-block;
    // display: flex;
    // align-items: center;
    svg {
      fill: #80adff;
      width: 16px;
      height: 16px;
    }
    span {
      color: #005aff;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .amount {
    width: 100%;
    margin-top: 20px;
    padding: 20px 12px;
    background: @form-back;
    border-radius: 8px;
    border: 1px solid transparent;
    // border: 1px solid #4788FF;
    &:nth-child(1) {
      margin-top: 0;
    }
    .amount-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: @text-color;
      font-weight: 600;
      margin-bottom: 5px;
      .balance {
        font-weight: 600;
      }
      button {
        width: 40px;
        height: 20px;
        background: #d3e2ff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        color: #005aff;
        font-size: 12px;
        font-weight: 600;
        padding: 0px;
        margin-left: 5px;
        &:hover {
          background-color: #005aff;
          color: #ffffff;
        }
      }
      .ant-btn[disabled] {
        background: rgba(#000000, 0.05);
        border-radius: 4px;
        font-weight: 600;
        color: rgba(#000000, 0.1);
        &:hover {
        }
      }
    }

    .trade-price {
      display: flex;
      justify-content: space-between;
      .ant-skeleton {
        margin-top: 3px;
      }
      input {
        font-size: 20px;
        font-weight: 600;
        color: @heading-color;
        padding: 0px;
      }
      .select-box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
        }
        .ant-select-selection-item {
          font-size: 16px;
          font-weight: 600;
          color: @heading-color;
          img {
            margin-right: 10px;
            width: 24px;
            height: 24px;
          }
          span {
            font-size: 16px;
            font-weight: 600;
            color: @heading-color;
          }
        }
        span {
          font-size: 16px;
          font-weight: 600;
          color: @heading-color;
        }
        .ant-select-arrow {
          margin-top: -7px;
          .icon {
            fill: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
  .long-add {
    display: block;
    width: 16px;
    height: 16px;
    margin: 10px auto -10px;
    svg {
      fill: #909db4;
    }
  }
  .amount-active {
    border: 1px solid #4788ff;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  .collateral {
    margin-top: 20px;

    .collateral-ratio-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .collateral-title- {
        font-weight: 600;
      }
      .input-box {
        display: flex;
        align-items: center;
        padding: 0 12px 0 1px;
        border-radius: 8px;
        border: 1px solid #ececec;
        color: #ffffff;

        .ant-input-suffix {
          color: @heading-color;
          font-weight: 600;
        }
        .ant-input-affix-wrapper {
          width: auto;
        }

        input {
          width: 40px;
          font-size: 16px;
          font-weight: 500;
          color: @heading-color;
          font-weight: 600;
        }

        .input-btn-max {
          padding: 4px 16px;
          background: rgba(#00ffc6, 0.1);
          border-radius: 4px;
          color: #00b590;
          font-weight: 600;
        }
        .input-btn-middle {
          padding: 4px 16px;
          background: rgba(#ffd600, 0.1);
          border-radius: 4px;
          color: #ffd600;
          font-weight: 600;
        }
        .input-btn-min {
          padding: 4px 16px;
          background: rgba(#fe0c08, 0.1);
          border-radius: 4px;
          color: #fe0c08;
          font-weight: 600;
        }
      }
      .input-box-active {
        border: 1px solid #4788ff;
      }
      .input-box-slide-active {
        border: 1px solid #fe0c08;
      }
    }

    .collateral-slider {
      margin-top: 16px;
    }
  }

  .tx-fee {
    padding: 20px 20px;
    background-color: @input-back;
    border-radius: 8px;
    margin-top: 20px;

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .tx-fee-text {
        font-size: 12px;
        font-weight: 600;
        color: @modal-close-color;
      }

      .tx-fee-price {
        font-size: 12px;
        font-weight: 600;
        color: @heading-color;
        display: flex;
        align-items: center;
        .icon {
          width: 18px;
          height: 18px;
          margin-left: 5px;
        }
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  .long-farm {
    margin-top: 20px;
    width: 100%;
    height: 48px;
    font-size: 16px !important;
    font-weight: 600;
    color: #fff;
    background: #005aff;
    // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    &:hover {
      background: #0d8fff;
    }
    span {
      font-size: 16px !important;
    }
  }
  .ant-btn[disabled] {
    span {
      color: #737373;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover {
    background: rgba(0, 0, 0, 0.05);
    color: #fff;
  }
}

@media (max-width: 750px) {
  .long-container {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    .amount {
      padding: 10px;
    }
    .confirm-order {
      width: 100%;
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;