/** @format */

.waitings-modal,
.success-modal,
.rejected-modal {
  img {
    margin-top: 16px;
    width: 60px;
    height: 60px;
  }

  p {
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;

    span {
      font-size: 14px;
      font-weight: 600;
      color: #005aff;
    }
    &:nth-of-type(2) {
      margin-top: 12px;
    }
  }

  a {
    font-size: 14px;
    font-weight: 600;
    color: #005aff;
    line-height: 30px;
  }

  .modal-btn {
    width: 160px;
    height: 40px;
    background: #005aff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 40px;
    span {
      font-size: 14px;
    }
  }
}
.rejected-modal {
  p {
    margin-bottom: 0;
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;