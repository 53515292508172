/** @format */

.coin-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.coin-list::-webkit-scrollbar-thumb {
  background-color: #f2f4fd;
  border-radius: 3px;
}
.coin-list {
  width: 464px;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgba(190, 200, 221, 0.3);
  border-radius: 10px;
  padding: 7px 30px;
  height: 100%;
  overflow: auto;
  .coin-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #f3f7fe;

    img {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
    .coin-item-left {
      font-size: 14px;
    }

    font-size: 14px;
    font-weight: 600;
    color: #333333;

    .coin-balance {
      text-align: right;
      .balance-num {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
      }

      .balance-price {
        font-size: 12px;
        font-weight: 400;
        color: #909db4;
        margin-top: 6px;
      }
    }
  }
}
@media (max-width: 750px) {
  .coin-list {
    width: 100%;
    margin-top: 10px;
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;