/** @format */

.unstake-container {
  .img {
    width: 42px;
    height: 42px;
    margin: 10px 0;
  }

  .liquidity-logo {
    display: flex;
    align-items: center;

    img {
      width: 44px;
      height: 28px;
      margin-right: 8px;
    }
  }

  .liquidity-name {
    font-size: 14px;
    font-weight: 550;
    color: @heading-color;
  }

  .amount {
    background: @form-back;
    border-radius: 8px;
    padding: 20px 12px;
    margin-top: 12px;

    .amount-header {
      font-weight: 600;
      color: #909db4;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 600;
        color: @heading-color;
        font-size: 16px;
      }
    }

    .amount-input {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 8px;

      input {
        font-size: 18px;
        font-weight: 600;
        color: @heading-color;
        padding: 0;
        margin-bottom: -5px;
      }

      .max-btn {
        padding: 4px 8px;
        max-height: 20px;
        // background: rgba(@primary-color, 0.23);
        background: #d6e4ff;
        border-radius: 4px;
        font-weight: 600;
        color: @primary-color;
        line-height: 1;
        font-size:12px;
        &:hover {
          background-color: #005aff;
          color: #ffffff;
        }
      }
      .ant-btn[disabled]{
        background: rgba(#000000,0.05);
        border-radius: 4px;
        font-weight: 600;
        color: rgba(#000000,0.1);
        &:hover {
          
        }
      }
    }
  }
  .stake-btn {
    width: 100%;
    height: 48px;
    background: @primary-color;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    font-weight: 600;
    color: @menu-back;
    span {
      font-size: 16px;
    }
    &:hover {
      background-color: #0d8fff;
    }
  }
  .ant-btn[disabled]{
    background: rgba(#000000,0.05);
    border-radius: 4px;
    font-weight: 600;
    box-shadow: none;
    border-radius: 8px;
    color: rgba(#000000,0.1);
    &:hover {
      
    }
  }
}
@media (max-width: 750px) {
  .img {
    margin-top: 0px;
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;