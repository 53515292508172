/** @format */

.symbol-chart {
  width: 580px;
  padding: 20px;
  background: @background-color-base;
  border-radius: 8px;
  height: 100%;
  .title {
    display: flex;
    justify-content: space-between;

    .symbol-info {
      display: flex;

      .logo {
        img {
          width: 40px;
          height: 40px;
        }
      }

      .symbol-name {
        margin-left: 8px;

        .name {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          color: #666;

          span {
            font-size: 18px;
            color: @heading-color;
          }
          .swap-symbol-tab {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            background: url(../../img/swap/icon-change.png);
            background-size: 100% 100%;
            &:hover {
              background: url(../../img/swap/icon-change-hover.png);
              background-size: 100% 100%;
            }
          }
        }

        .source {
          color: @modal-close-color;
          margin-top: 6px;
          font-size: 14px;
          img {
            width: 14px;
            height: 14px;
            margin-left: 3px;
          }
        }
      }
    }

    .symbol-total {
      display: flex;
      align-items: center;

      .volume,
      .liquidity {
        margin-left: 53px;
      }
      .premium {
        position: relative;
        .leabl {
          font-size: 12px;
          font-weight: 500;
          color: @text-color;
          display: flex;
        }
        p {
          display: none;
          width: 190px;
          position: absolute;
          background-color: #f7f7f7;
          top: 20px;
          left: 0;
          z-index: 99;
          padding: 10px;
          border-radius: 8px;
        }
        img {
          width: 16px;
          height: 16px;
          margin-left: 5px;
        }
        &:hover {
          p {
            display: block;
          }
        }
      }
      .liquidity {
        margin-top: 0;
        text-align: right;
      }
      .title {
        font-size: 12px;
        font-weight: 500;
        color: @text-color;
      }

      .text {
        font-size: 12px;
        font-weight: 600;
        color: @heading-color;
        margin-top: 8px;
      }
    }
  }

  .chart-info {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .symbol-price {
      .price {
        font-size: 26px;
        font-weight: 600;
        color: @heading-color;
        line-height: 1;
        position: relative;

        img {
          width: 14px;
          height: 14px;
          margin-left: 5px;
          &:hover {
            & + .tips-text {
              display: block;
            }
          }
        }

        .tips-text {
          padding: 10px 12px;
          background: rgba(0, 90, 255, 0.04);
          border-radius: 4px;
          line-height: 1;
          // text-align: center;
          color: @text-color;
          position: absolute;
          top: 30px;
          right: -50px;
          display: none;
          z-index: 99;
          p {
            margin-top: 10px;
          }
          // box-shadow: 0px 0px 10px 0px #0D0F19;
        }
      }

      .rise {
        display: inline-block;
        margin-top: 8px;
        padding: 5px 8px;
        background: @up-back;
        font-weight: 600;
        border-radius: 5px;
        border-radius: 5px;
        color: @up-color;
        font-size: 12px;
        .icon {
          width: 8px;
          height: 8px;
          fill: @up-color;
          margin-left: 4px;
        }
      }
    }

    .time-type {
      display: flex;
      height: 100%;
      font-size: 12px;
      font-weight: 600;

      span {
        cursor: pointer;
        padding: 4px 7px;
        border-radius: 4px;
        color: @text-color;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .symbol-chart {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    .title {
      display: block;
      margin-top: 0px;
      .symbol-total {
        display: flex;
        justify-content: space-between;
        margin-top: 21px;
      }
    }
    .chart-view,
    .chart-info {
      display: none;
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;