/** @format */

.manageRight-close-container {
  width: 400px;
  padding: 20px 16px;
  background: @component-background;
  border-radius: 8px;
  // margin-left: 20px;

  .tx-fee {
    padding: 20px 20px;
    background-color: @input-back;
    border-radius: 8px;
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .tx-fee-text {
        font-size: 12px;
        font-weight: 600;
        color: @modal-close-color;
      }

      .tx-fee-price {
        font-size: 12px;
        font-weight: 500;
        color: @heading-color;
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
  .available {
    margin-top: 20px;
    width: 100%;
    // height: 26px;
    background: #fee6e6;
    border-radius: 8px;
    .content {
      color: #ff0000;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        line-height: 26px;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      svg {
        width: 16px;
        height: 16px;
        margin-left: 2px;
        fill: #ff0000;
      }
    }
  }
  .close {
    margin-top: 20px;
    width: 100%;
    height: 48px;
    font-size: 16px !important;
    font-weight: 600;
    color: #fff;
    background: #005aff;
    // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    &:hover {
      background: #0d8fff;
    }
    span {
      font-size: 16px !important;
    }
  }
  .ant-btn[disabled] {
    span {
      color: #737373;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover {
    background: rgba(0, 0, 0, 0.05);
    color: #fff;
  }
}

@media (max-width: 750px) {
  .manageRight-close-container {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    .amount {
      padding: 10px;
    }
    .confirm-order {
      width: 100%;
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;