/** @format */

.accountCard-container {
  .ant-input {
    background-color: @form-back;
    height: 40px;
    border-radius: 8px;
  }
  .base {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      line-height: 14px;
    }
    .change {
      width: 60px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: @primary-color;
      border-radius: 4px;
      opacity: 0.4;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .ant-btn {
      width: 150px;
      height: 40px;
      border-radius: 8px;
      opacity: 0.2;
      border: 1px solid #000;
      span {
        color: #000;
      }
      &:nth-child(2) {
        background: @primary-color;
      }
    }
  }
}
@media (max-width: 750px) {
  .logo-img {
    margin-top: 0px;
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;