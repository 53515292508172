/** @format */

.short-order-confirm-conntent {
  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #333330;
    }
    img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }
    .mint-assets {
      font-size: 18px;
      font-weight: 500;
      color: #333333;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #909db4;
      }
    }
  }

  .detail {
    padding: 20px;
    background: #f7f7f7;
    border-radius: 8px;
    margin-top: 19px;

    .detail-item {
      display: flex;
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .leabl {
        font-size: 14px;
        color: #909db4;
      }

      .text {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
      }
    }
  }

  .warning {
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    margin-top: 10px;
    background: rgba(#fe6012, 0.1);
    border-radius: 8px;
    font-size: 12px;
    color: #fe5d0d;
    padding: 9px 12px;
    display: flex;
    text-align: left;
    align-items: center;
    span {
      line-height: 1.2;
    }
  }
  .tips-available {
    margin-top: 20px;
    padding: 9px 10px;
    width: 100%;
    // height: 26px;
    text-align: left;
    background: rgba(#fe6012, 0.1);
    border-radius: 8px;
    .content {
      color: #fe5d0d;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      span {
        line-height: 18px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      svg {
        width: 12px;
        height: 12px;
        margin-left: 2px;
        fill: @icon-color;
      }
    }
  }
  .confirm-btn {
    width: 155px;
    height: 40px;
    background: #005aff;
    border-radius: 8px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 20px;
    padding: 0;
    .mintSpan {
      display: inline-block;
      width: 155px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;