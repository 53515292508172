/** @format */

.manageRight-table-content {
  width: 400px;
  padding: 10px 0px;
  background: @component-background;
  border-radius: 8px;
  margin-left: 20px;
  height: 100%;
  .icon {
    width: 16px;
    height: 16px;
    fill: #909db4;
    margin-right: 5px;
  }
  .action-btn + .action-btn {
    margin-left: 20px;
  }
  .table-header-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 35px 0 20px;
    margin-bottom: 0;
    svg {
      width: 20px;
      height: 20px;
    }
    .header-tab-item {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #909db4;
      display: flex;
      align-items: center;
      // margin-right: 40px;
      position: relative;
      padding: 10px 0px;
    }

    .header-tab-item-active {
      font-size: 14px;
      font-weight: 500;
      color: #005aff;
      .icon {
        fill: #005aff;
      }
      &::after {
        content: '';
        width: 20px;
        height: 2px;
        background: #005aff;
        border-radius: 1px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
  }
}
@media (max-width: 750px) {
  .manageRight-table-content {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    .amount {
      padding: 10px;
    }
    .confirm-order {
      width: 100%;
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;