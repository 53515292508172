/** @format */

.staking-left {
  .staking-left-title {
    font-size: 16px;
    font-weight: 600;
    color: @text-color;
    padding-bottom: 20px;
    border-bottom: 1px solid @menu-active-back;
    display: flex;
    span {
      font-size: 16px;
    }
    img {
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }
    .tip {
      position: relative;
      .tip-img {
        position: absolute;
        top: 0;
        right: -20px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
      &:hover {
        .tip-hover {
          display: block;
        }
      }
      .tip-hover {
        display: none;
        width: 467px;
        height: 130px;
        background: #ecf0f7;
        color: @text-color;
        z-index: 9999;
        padding: 10px;
        position: absolute;
        top: 0px;
        right: -495px;
        border-radius: 8px;
        p {
          font-weight: 400;
          line-height: 20px;
          margin-top: 15px;
          &:nth-child(1) {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .nsdx-price {
    padding: 17px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    background: @input-back;
    border-radius: 8px;

    .nsdx-text {
      font-size: 14px;
      font-weight: 500;
      color: @heading-color;
    }

    .nsdx-usd {
      font-size: 18px;
      font-weight: bold;
      color: @heading-color;

      span {
        font-size: 14px;
        font-weight: 500;
        color: @text-color;
        margin-right: 3px;
      }
    }
  }

  .claimable-rewards {
    padding: 16px 12px;
    background: @input-back;
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;

    .total-nsdx {
      margin-top: 8px;
    }
  }

  .nsdx-usd {
    margin-top: 16px;

    .nsdx-num {
      font-weight: bold;
      color: #333333;
      font-size: 18px;
    }

    .nsdx-num-usd {
      font-size: 14px;
      font-weight: 500;
      color: #909db4;
      margin-top: 8px;
    }
  }
  .auto-nsdx {
    width: 300px;
    padding: 20px 20px;
    border-radius: 10px;
    display: block;
    background: @modal-content-bg;

    .claim-btn {
      width: 100%;
      height: 48px;
      background: #005aff;
      // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 16px;
      &:hover {
        background: #0d8fff;
      }
      span {
        font-size: 14px;
      }
    }
    .ant-btn[disabled] {
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      background: rgba(0, 0, 0, 0.05);
      color: #737373;
    }
  }
  .nsdx-balance {
    // margin-top: 19px;
    width: 300px;
    padding: 20px 20px;
    border-radius: 10px;
    display: block;
    background: @modal-content-bg;
  }
}

@media (max-width: 750px) {
  .staking-left {
    display: none;
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;