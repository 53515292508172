/** @format */

.claimBounty-container {
  .claimBountyTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-left: 4px;
    .left {
      font-size: 18px;
      color: @heading-color;
      font-weight: 550;
    }
    .right {
      text-align: right;
      p {
        font-size: 18px;
        color: @heading-color;
        font-weight: 600;
        height: 30px;
      }
      .rightSpan {
        font-size: 14px;
        color: @text-color;
      }
    }
  }

  .text {
    color: @text-color;
    margin-top: 20px;
    line-height: 14px;
    height: 80px;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 20px 10px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      span {
        color: @text-color;
        font-size: 14px;
        font-weight: 500;
      }
      p {
        color: @heading-color;
        font-size: 14px;
        font-weight: 550;
      }
    }
  }

  .claimBounty-btn {
    width: 100%;
    height: 48px;
    background: @primary-color;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    font-weight: 600;
    color: @menu-back;
    span {
      font-size: 16px;
    }
    &:hover {
      background-color: #0d8fff;
    }
  }
}
@media (max-width: 750px) {
  .logo-img {
    margin-top: 0px;
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;