/** @format */

.farm-setting {
  text-align: left;

  .farm-setting-content {
    .slippage-tolerance {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    .input-content {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      .slippage-item {
        width: 72px;
        height: 32px;
        background: rgba(#ffffff, 0.1);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.05);
      }
      .slippage-item-active {
        border: 1px solid #005aff;
        color: #005aff;
      }

      input {
        width: 72px;
        background: rgba(#ffffff, 0.1);
        border-radius: 8px;
      }

      .ant-input-affix-wrapper {
        width: 72px;
        border-radius: 8px;
      }
    }
  }
  .tips-available {
    margin-top: 20px;
    width: 100%;
    // height: 26px;
    padding: 5px;
    background: rgba(#fe6012, 0.1);
    border-radius: 8px;
    .content {
      color: #fe5d0d;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        line-height: 18px;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      svg {
        width: 12px;
        height: 12px;
        margin-left: 2px;
        fill: @icon-color;
      }
    }
  }
  .available {
    margin-top: 20px;
    width: 100%;
    // height: 26px;
    background: #fee6e6;
    border-radius: 8px;
    .content {
      color: #ff0000;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        line-height: 26px;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      svg {
        width: 12px;
        height: 12px;
        margin-left: 2px;
        fill: @icon-color;
      }
    }
  }
  .deadline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    .ant-input {
      width: 72px !important;
      background: rgba(#ffffff, 0.1);
      border-radius: 8px;
    }
  }
  .farm-setting-alert {
    margin-top: 12px;
    color: #ff4343;
  }
  .btnBox {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      cursor: pointer;
      width: 45%;
      height: 35px;
      font-size: 14px !important;
      font-weight: 500;
      color: #fff;
      // color: #000;
      // background: #fff;
      background: #005aff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      &:hover {
        background: #0d8fff;
      }
      &:nth-child(1) {
        color: #000;
        background: #fff;
        border: 1px solid #005aff;
        &:hover {
          background: #f2f4fc;
          color: #005aff;
        }
      }
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;