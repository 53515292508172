/** @format */

.wallet-list {
  .wallet-item {
    display: flex;
    align-items: center;
    background: @form-back;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 8px 16px 8px 20px;
    position: relative;
    cursor: pointer;
    &:not(:first-child) {
      margin-top: 20px;
    }

    &:hover {
      background: #dde4ff;
    }
    &:focus {
      border: 1px solid @primary-color;
      &:after {
        content: '';
        width: 8px;
        height: 8px;
        background: @primary-color;
        position: absolute;
        right: 16px;
        border-radius: 50%;
      }
    }
    img {
      width: 32px;
      height: 32px;
    }
    .wallet-name {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 600;
      color: @heading-color;
    }
  }
}

.wallet-account-box {
  .platform,
  .account {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .platform {
    p {
      font-size: 14px;
      font-weight: 600;
    }
    button {
      width: 60px;
      height: 20px;
      background: #d3e2ff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      color: #005aff;
      font-size: 12px;
      font-weight: 600;
      padding: 0px;
      margin-left: 5px;
      &:hover {
        background-color: #005aff;
        color: #ffffff;
      }
    }
  }
  .account {
    height: 48px;
    margin-top: 20px;
    background: @input-back;
    padding: 0px 12px;
    color: #000;
    a {
      color: #909db4;
      cursor: pointer;
    }
    p {
      font-weight: 600;
    }
  }
}
.account-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button,
  a {
    width: 180px;
    height: 40px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
  }
  a {
    display: block;
    background: @primary-color;
    color: #fff;
    text-align: center;
    line-height: 40px;
  }
}
@media (max-width: 750px) {
  .wallet-account-box {
    .account {
      p {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;