/** @format */

.farm-pool-list {
  display: flex;
  flex-wrap: wrap;
  .farm-pool-item {
    width: 33%;
    max-width: 320px;
    margin-top: 20px;
    padding: 20px;
    background: @menu-back;
    border-radius: 10px;
    margin-right: 20px;
    &:nth-child(3n) {
      margin-right: 0px;
    }
    .pool-header {
      display: flex;

      .pool-logo {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      .pool-name {
        font-size: 14px;
        font-weight: 600;
        color: @heading-color;
      }

      .pool-source {
        font-size: 12px;
        font-weight: 400;
        color: @text-color;
        margin-top: 6px;
      }
    }

    .farmPoolBox {
      background: @input-back;
      border-radius: 8px;
      margin-top: 10px;
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      padding: 0 12px;
      .apr-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 0px;
        .short-apr,
        .short-TVL {
          margin-top: 15px;
        }
        .long-apr-title,
        .long-TVL-title {
          font-size: 16px;
          font-weight: 600;
          color: @heading-color;
          line-height: 16px;
        }

        .long-apr-text {
          font-size: 12px;
          font-weight: 600;
          color: @text-color;
          margin-top: 6px;
        }
        .long-TVL-title {
          width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .long-TVL,
        .short-TVL {
          position: relative;
        }
        :hover {
          .hover {
            display: block;
          }
        }
        .hover {
          background: #fff;
          padding: 8px;
          border-radius: 4px;
          color: #000;
          font-size: 14px;
          position: absolute;
          top: -30px;
          left: 0;
          display: none;
        }
      }
    }

    .amm-price {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-top: 20px;
      }

      .leabl {
        font-size: 14px;
        font-weight: 500;
        color: @text-color;
      }

      .text {
        font-size: 14px;
        font-weight: 600;
        color: @heading-color;
      }
      .premium {
        position: relative;
      }
      p {
        display: none;
        width: 190px;
        position: absolute;
        background-color: #f7f7f7;
        top: 20px;
        left: 0;
        z-index: 99;
        padding: 10px;
        border-radius: 8px;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
      &:hover {
        p {
          display: block;
        }
      }
    }

    .action-btn {
      margin-top: 20px;
      // display: flex;
      width: 100%;
      // justify-content: space-between;
      .farmBtn {
        span {
          font-size: 14px;
          font-weight: 900;
        }
        width: 46%;
        height: 48px;
        background: @up-color;
        border-radius: 8px;
        font-weight: 900;
        color: @menu-back;
        border: none;
        &:hover {
          background: #0d8fff;
        }
      }
      .shortBtn {
        margin-left: 16px;
      }
      .longBtn {
        width: 100%;
      }
    }
  }
}
@media (max-width: 750px) {
  .farm-pool-list {
    justify-content: center;
    .farm-pool-item {
      width: 355px;
      margin-right: 0;
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;