/** @format */

.title {
  font-size: 18px;
  font-weight: 600;
  color: @heading-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .position {
    position: relative;
    span {
      font-size: 12px;
      font-weight: 600;
      color: #909db4;
    }
    .manage {
      color: @heading-color;
      font-size: 18px;
      margin-right: 40px;
    }
    .farm {
      color: @heading-color;
      font-size: 18px;
      margin-right: 65px;
    }
    svg {
      fill: #909db4;
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: -3px;
      left: 80px;
    }
  }
  .icon {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    fill: @text-color;
    margin-left: 8px;
  }
  .h5-account {
    display: none;
  }
  .pc-account {
    display: block;
  }
}
@media (max-width: 750px) {
  .title {
    width: 100%;
    margin-top: 20px;
    .h5-account {
      display: block;
    }
    .pc-account {
      display: none;
    }
    .address {
      span {
        width: 80%;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
      }
    }
  }
}

@primary-color: #005AFF;@link-color: #1DA57A;@text-color: #909DB4;@heading-color: #333333;@border-radius-base: 2px;@modal-content-bg: #fff;@modal-header-bg: #fff;@modal-footer-border-color-split: #fff;@modal-header-border-color-split: #fff;@modal-close-color: #909DB4;@component-background: #fff;@border-color-base: rgba(0, 0, 0, 0.05);@btn-default-borde: rgba(0, 0, 0, 0.05);@icon-color-hover: #005AFF;@background-color-base: #fff;@up-back: #E6EFFF;@up-color: #005AFF;@down-back: rgba(254,12,8, 0.1);@down-color: #FF0000;@icon-color: #909DB4;@body-background: #F7F8FB;@menu-active-back: #F3F7FE;@menu-active-color: #005AFF;@menu-back: #FFFFFF;@menu-shadow: rgba(186, 200, 224, 0.5);@input-back: #F7F7F7;@form-back: #F2F4FC;